<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Impresión de guías</h5>
        <div class="p-fluid formgrid grid">
          <div class="field col-6">
            <label for="guidesDate">Fecha a imprimir</label>
            <Calendar id="guidesDate" v-model="guidesDate" :showTime="false"
                      hourFormat="12" dateFormat="dd/mm/yy" :showSeconds="false"/><!--:minDate="new Date()"-->
          </div>
          <div class="field col-6">
            <label for="guidesDate">&nbsp;</label>
            <Button icon="pi pi-print" label="Imprimir" @click="printGuides"></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toast />
</template>

<script>
import { auth, pickupSequence } from "../logic/firebase";
var guidesDate;

export default {
  data() {
    return {
      guidesDate: guidesDate
    }
  },


  methods: {
    async printGuides(){
      if(this.guidesDate){
        const year = this.guidesDate.getFullYear()
        const month = ("0" + (this.guidesDate.getMonth() + 1)).slice(-2)
        const day = ("0" + this.guidesDate.getDate()).slice(-2)
        const dateString = year + '-' + month + '-' + day;
        await pickupSequence.doc(dateString).get().then((doc) => {
          if(doc.exists){
            this.displayToast("success", "Imprimiendo guías",
                "Espera un momento.", 1000);
            this.$router.push({
              path: '/PrintGuides',
              query: {
                date: dateString
              }
            })
          }else{
            this.displayToast("error", "No hay guías para esa fecha",
                "Genera la secuencia desde la app primero.", 2000);
          }
        }).catch((error) => {
          console.log(error)
        })
      }else{
        this.displayToast("error", "Fecha inválida",
            "Por favor selecciona una fecha con el selector.", 2000);
      }
    },//printGuides

    displayToast(severity, summary, details, life){
      /*
       * SEVERITY: success, info, warn, error
       */
      this.$toast.add({severity: severity, summary: summary, detail: details, life: life});
    },//displayToast
  },


  mounted() {

  },
}
</script>

<style scoped>
::v-deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}
</style>